/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, TextField, Checkbox, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  BulkAddFilters,
  AddFilter,
  BulkRemoveFilters,
  RemoveFilter,
} from '../../signals/filterService';
import { filters } from '../../signals/filterService';

const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkboxCheckedIcon = <CheckBoxIcon fontSize="small" />;
const SELECT_ALL_MIN_COUNT = 7;
const SELECT_ALL_OPTION = { name: 'Select all', value: -1 };

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: '#ffff',
    paddingTop: '2px',
    paddingBottom: '2px',
    fontSize: '15px',
    borderRadius: '10px',
    '& .MuiChip-root': {
      fontSize: '0.70rem',
      height: '26px',
      width: '76px',
    },
    '& .MuiInputBase-root': {
      padding: '0 !important',
      borderRadius: '10px',
      background: 'white',
    },
  },
}));
export default function FilterAutocompleteSelect({
  title,
  _filters,
  filterTag,
}) {
  const [selectedValues, setSelectedValues] = useState([]);
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);
  const [inputValue, setInputValue] = useState('');

  // useEffect(
  //   function updateSelectedValuesWhenFiltersChange() {
  //     setSelectedValues((prevSelectedValues) =>
  //       prevSelectedValues.filter((selectedValue) =>
  //         filters.value.includes(selectedValue)
  //       )
  //     );
  //   },
  //   [filters.value]
  // );

  useEffect(
    function handleResetFilters() {
      if (!filters.value.length) {
        setSelectedValues([]);
      }
    },
    [filters.value]
  );

  const options =
    _filters.length > SELECT_ALL_MIN_COUNT
      ? [SELECT_ALL_OPTION].concat(_filters)
      : _filters;

  const handleClear = () => {
    BulkRemoveFilters(selectedValues, filterTag);
    setSelectedValues([]);
  };

  const handleRemoveOption = (selectedOptions) => {
    const removed = selectedValues.find(
      (option) => !selectedOptions.includes(option)
    );
    if (removed === SELECT_ALL_OPTION) {
      BulkRemoveFilters(selectedValues);
      setSelectedValues([]);
    } else {
      RemoveFilter(removed);
      setSelectedValues(
        selectedOptions.filter((option) => option !== SELECT_ALL_OPTION)
      );
    }
  };

  const handleSelectOption = (selectedOptions) => {
    const added = selectedOptions.find(
      (option) => !selectedValues.includes(option)
    );
    if (added === SELECT_ALL_OPTION) {
      BulkAddFilters(_filters.filter((f) => !selectedValues.includes(f)));
      setSelectedValues(options);
    } else {
      AddFilter(added);
      const allSelected = selectedOptions.length === _filters.length;
      if (allSelected) {
        setSelectedValues(options);
      } else {
        setSelectedValues(selectedOptions);
      }
    }
  };

  const handleChange = (event, selectedOptions, reason) => {
    switch (reason) {
      case 'select-option':
        handleSelectOption(selectedOptions);
        break;
      case 'remove-option':
        handleRemoveOption(selectedOptions);
        break;
      case 'clear':
        handleClear();
        break;
      default:
        break;
    }
  };

  const getOptionLabel = (option) => {
    if (option === SELECT_ALL_OPTION) {
      return option.name;
    } else {
      return `${option.name} (${option.count})`;
    }
  };

  return (
    <div className={classes.inputRoot}>
      <span>{title}</span>
      <Autocomplete
        className={classes.inputRoot}
        disableCloseOnSelect
        multiple
        options={options}
        value={selectedValues}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        inputValue={inputValue}
        limitTags={isHovered ? 1 : 2}
        getOptionSelected={(option, value) => option.value === value.value}
        getOptionLabel={getOptionLabel}
        renderTags={(value, getTagProps) =>
          // The "Select all" option shouldn't be displayed among tags
          value
            .filter((f) => f !== SELECT_ALL_OPTION)
            .map((filter, index) => (
              <Chip
                key={filter.name}
                label={filter.name}
                {...getTagProps({ index })}
              />
            ))
        }
        renderInput={(params) => (
          <TextField
            onChange={(e) => setInputValue(e.target.value)}
            {...params}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              classes: { input: classes.input },
            }}
          />
        )}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              color="primary"
              icon={checkboxIcon}
              checkedIcon={checkboxCheckedIcon}
              style={{ marginRight: 2, padding: 0 }}
              checked={selected}
            />
            {getOptionLabel(option)}
          </>
        )}
        onChange={handleChange}
      />
    </div>
  );
}

FilterAutocompleteSelect.propTypes = {
  title: PropTypes.string.isRequired,
  filterTag: PropTypes.string.isRequired,
};
