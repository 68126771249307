/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, FC, useRef } from 'react';
import {
  withStyles,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  TableHead,
  MenuItem,
  Select,
  Chip,
  WithStyles,
  Typography,
  Grid,
  Tooltip,
} from '@material-ui/core';
import TrendIcon from '../icons/TrendIcon';
import DashboardModal from '../dashboard/DashboardModal';
import NoDataText from '../common/NoDataText';
import TimeServiceProvider from '../../services/TimeService';
import moment from 'moment';
import CircularProgressCentral from '../common/CircularProgressCentral';
import UserPreferenceService from '../../services/UserPreferenceService';
import { createStyles, makeStyles } from '@material-ui/styles';
import { WidgetCode } from './widgetCodes';
import { TopicFilterContext } from '../../context/TopicFilterContextProvider';
import {
  PossibleReportWidgetDataDto,
  Trends,
} from '../../api/originalMappings';
import { filters } from '../../signals/filterService';
import {
  BubbleSeriesData,
  createBubbleChartOptions,
  getColorByValueChange,
} from '../charts/PackedBubbleChart';
import Highcharts, { Chart } from 'highcharts';
import HighchartsReact, {
  HighchartsReactProps,
  HighchartsReactRefObject,
} from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { signal } from '@preact/signals-react';

HC_more(Highcharts);

export const selectedTopicWidgetOption = signal<string>('');

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    padding: '0px 16px 0 16px',
  },
  periodInfo: {
    fontSize: '.8em',
    color: 'grey',
  },
  viewAllButton: {
    width: '100%',
    padding: 0,
  },
  chip: {
    marginTop: '4px',
    width: '120px',
  },
  up: {
    background: '#f7cccc',
    opacity: 0.5,
    '&:active': { background: '#f7cccc' },
    '&:focus': { background: '#f7cccc' },
  },
  down: {
    background: '#dbefdc',
    opacity: 0.5,
    '&:active': { background: '#dbefdc' },
    '&:focus': { background: '#dbefdc' },
  },
  flat: {
    background: '#dfe5e8',
    opacity: 0.5,
    '&:active': { background: '#dfe5e8' },
    '&:focus': { background: '#dfe5e8' },
  },
  activeFilter: {
    opacity: 1,
  },
  white: {
    color: 'white',
  },
  cardContent: {
    overflow: 'hidden',
  },
  cardActions: {
    flexShrink: 0,
  },
  tableItemCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tooltip: {
    fontSize: '0.8em',
  },
});

const filterNames = {
  up: 'up',
  down: 'down',
  flat: 'flat',
};
const filterNamesCount = Object.keys(filterNames).length;

const filtersFunc = (function () {
  const rounder = (val: number) => Math.round(val * 100);
  return {
    [filterNames.up]: (a: number, b: number) => rounder(a) - rounder(b) > 0,
    [filterNames.down]: (a: number, b: number) => rounder(a) - rounder(b) < 0,
    [filterNames.flat]: (a: number, b: number) => rounder(a) - rounder(b) === 0,
  };
})();

const userPreferenceService = new UserPreferenceService();
const timeService = TimeServiceProvider();

const formatPercentage1dp = (value: number) => `${value.toFixed(1)}%`;
const formatPercentage3dp = (value: number) => `${value.toFixed(3)}%`;

const useStyles = makeStyles({
  iconWhite: {
    color: 'white',
  },
  iconBlack: {
    color: 'black',
  },
});

const convertTrendsToArray = (trendsObject: Trends): FormattedTrend[] => {
  if (!trendsObject) return [];

  return Object.entries(trendsObject)
    .map(([key, value]) => ({
      label: key,
      current: value.trends.current,
      prev: value.trends.prev,
      organisation: value.organisation,
      parentClassificationName: value.parentClassificationName,
    }))
    .sort((a, b) => b.current - a.current);
};

export interface FormattedTrend {
  label: string;
  current: number;
  prev: number;
  organisation: string;
  parentClassificationName: string;
}

interface Props extends WithStyles<typeof styles>, HighchartsReactProps {
  trendsFetchFunc: (widgetCode: string) => Promise<PossibleReportWidgetDataDto>;
  limit: number;
  title: string;
  info: string;
  noDataLabel: string;
  classificationType: string;
  previousPeriod: string;
  currentPeriod: string;
  reportsPercent: string;
  widgetName: string;
  additionalTopicsOptions: any;
  defaultTopicsSource: any;
}

const ClassificationTrendsBase: FC<Props> = ({
  classes,
  trendsFetchFunc,
  limit,
  title,
  info,
  noDataLabel,
  classificationType,
  previousPeriod,
  currentPeriod,
  reportsPercent,
  widgetName,
  additionalTopicsOptions,
  defaultTopicsSource,
  ...highChartsProps
}) => {
  const [showModal, setShowModal] = useState(false);
  const [appliedFilterNames, setAppliedFilterNames] = useState<string[]>([]);
  const [trends, setTrends] = useState<FormattedTrend[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [additionalTopicFilter, setAdditionalTopicFilter] = useState(
    additionalTopicsOptions?.length === 0
      ? 0
      : additionalTopicsOptions?.at(0).enum
  );

  const[selectedTopicOption, setSelectedTopicOption] = useState(0);

  const _classes = useStyles();

  const toggleFilterName = (name: string) => {
    setAppliedFilterNames((prevState) => {
      return prevState.includes(name)
        ? prevState.filter((item) => item !== name)
        : prevState.concat([name]);
    });
  };

  const [topicFilters, setTopicFilters] = useContext(TopicFilterContext);

  useEffect(() => {
    setAdditionalTopicFilter(
      additionalTopicsOptions?.length == 0
        ? 0
        : additionalTopicsOptions?.at(0).enum
    );
  }, [additionalTopicsOptions]);

  useEffect(() => {
    if (!additionalTopicsOptions) return;

    const filterMapping = {
      //Setting state for Observation
      64: additionalTopicsOptions.find((e) => e.enum === 64),
      //Setting state for Field Verification
      32: additionalTopicsOptions.find((e) => e.enum === 32),
      //Setting state for no filter
      0: {
        key: '',
        value: '',
        enum: null,
      },
    };

    // BulkAddFilters(filterMapping[additionalTopicFilter]);
    setTopicFilters(filterMapping[additionalTopicFilter]);
  }, [additionalTopicFilter]);

  const fetchTrends = () => {
    setIsLoading(false);

    const widgetCode = WidgetCode.topicTrends;

    trendsFetchFunc(widgetCode).then((res: Trends) => {
      //remove 'None' key-value pair if present
      if (res['None']) delete res['None'];

      setTrends(convertTrendsToArray(res));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    timeService.subscribe(fetchTrends);
    fetchTrends();

    return () => {
      timeService.unsubscribe(fetchTrends);
    };
  }, []);

  useEffect(
    function getTrendsWhenFilterChanges() {
      fetchTrends();
    },
    [filters.value]
  );

  useEffect(() => {
    const savedFilterNames = userPreferenceService.get(
      `${widgetName}_TrendsAppliedFilterNames`
    );
    if (savedFilterNames && savedFilterNames.length) {
      setAppliedFilterNames(savedFilterNames);
    }
  }, []);

  useEffect(() => {
    userPreferenceService.save(
      `${widgetName}_TrendsAppliedFilterNames`,
      appliedFilterNames
    );
  }, [appliedFilterNames]);

  useEffect(() => {
    timeService.subscribe(fetchTrends);
    fetchTrends();

    return () => {
      timeService.unsubscribe(fetchTrends);
    };
  }, [additionalTopicFilter]);

  const applyFilters = (trends: any[]): FormattedTrend[] => {
    let result = appliedFilterNames.length === 0 ||
      appliedFilterNames.length === filterNamesCount
      ? trends
      : trends.filter((trend) => {
          return appliedFilterNames.some((filterName) =>
            filtersFunc[filterName](trend.current, trend.prev)
          );
        });

        if(selectedTopicOption >= 0 && additionalTopicsOptions?.length > 0){
          const selectedTrendsOption = additionalTopicsOptions[selectedTopicOption];

          if(selectedTrendsOption){
            const key = selectedTrendsOption.key;

            if(key){
              result = result.filter((trend) => trend.parentClassificationName.toLowerCase() === key.toLowerCase() || trend.parentClassificationName.toLowerCase() === key.toLowerCase().replace(/ /g,''));
            }
          }
        }

        return result;
  };

  const [currentPeriodStart, currentPeriodEnd] = timeService.domain;
  const [prevPeriodStart, prevPeriodEnd] = timeService.getPrevDomain();
  const formatDate = (date: moment.MomentInput) =>
    moment(date).format('D/MMM/YY');

  const [alignment, setAlignment] = React.useState<'table' | 'visual'>('table');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: 'table' | 'visual'
  ) => {
    setAlignment(newAlignment);
  };

  const handleTopicOptionChange = (event) => {
    selectedTopicWidgetOption.value = additionalTopicsOptions[event].key;
    setSelectedTopicOption(event);
    applyFilters(trends);
  };

  const VisualView = () => {
    const categories = trends.map((x) => x.label);

    const previousTrendsData: BubbleSeriesData = applyFilters(trends).map(
      (trend) => ({
        name: trend.label,
        data: [
          {
            name: trend.label,
            value: parseFloat(trend.prev.toFixed(1)),
            color: getColorByValueChange(trend.prev, trend.current),
            dataLabels: {
              allowOverlap: false,
            },
          },
        ],
      })
    );

    const currentTrendsData: BubbleSeriesData = applyFilters(trends).map(
      (trend) => ({
        name: trend.label,
        data: [
          {
            name: trend.label,
            value: parseFloat(trend.current.toFixed(1)),
            color: getColorByValueChange(trend.prev, trend.current),
            dataLabels: {
              allowOverlap: false,
            },
          },
        ],
      })
    );

    let syncCharts = (chart1: Chart, chart2: Chart) => {
      if (!chart1 || !chart2) return;

      let syncHover = (pointName: any, currentChart: any, charts: any[]) => {
        charts.forEach((chart) => {
          if (chart !== currentChart) {
            chart.series.forEach((series: { data: any[] }) => {
              series.data.forEach((point) => {
                if (point.name === pointName) {
                  point.graphic.addClass('full');
                } else {
                  point.graphic.addClass('dim');
                }
              });
            });
          }
        });
      };

      let syncTooltip = (pointName: string, charts: any[]) => {
        charts.forEach((chart) => {
          chart.series.forEach((series) => {
            series.data.forEach((point) => {
              if (point.name === pointName) {
                chart.tooltip.refresh(point);
              }
            });
          });
        });
      };

      [chart1, chart2].forEach((chart) => {
        chart.series.forEach((series) => {
          series.data.forEach((point) => {
            point.graphic.on('mouseover', function () {
              let pointName = point.name;
              syncHover(pointName, chart, [chart1, chart2]);
              syncTooltip(pointName, [chart1, chart2]);
            });
            point.graphic.on('mouseout', function () {
              [chart1, chart2].forEach((chart) => {
                chart.series.forEach((series) => {
                  series.points.forEach((point) => {
                    point.graphic.removeClass('dim');
                    point.graphic.addClass('full');
                  });
                });
              });
            });
          });
        });
      });
    };

    const previousTitle = `${formatDate(prevPeriodStart)} - ${formatDate(
      prevPeriodEnd
    )}`;
    const currentTitle = `${formatDate(currentPeriodStart)} - ${formatDate(
      currentPeriodEnd
    )}`;

    const chart1ComponentRef = useRef<HighchartsReactRefObject>(null);
    const chart2ComponentRef = useRef<HighchartsReactRefObject>(null);

    useEffect(() => {
      syncCharts(
        chart1ComponentRef.current?.chart,
        chart2ComponentRef.current?.chart
      );
    }, [chart1ComponentRef.current, chart2ComponentRef.current]);

    let chart1Options: any, chart2Options: any;
    chart1Options = createBubbleChartOptions(
      previousTrendsData,
      previousTitle,
      categories
    );
    chart2Options = createBubbleChartOptions(
      currentTrendsData,
      currentTitle,
      categories
    );

    useEffect(() => {
      chart1Options = createBubbleChartOptions(
        previousTrendsData,
        previousTitle,
        categories
      );
      chart2Options = createBubbleChartOptions(
        currentTrendsData,
        currentTitle,
        categories
      );
    }, [trends]);

    return previousTrendsData || currentTrendsData ? (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ minWidth: '50%' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={chart1Options}
            ref={chart1ComponentRef}
            {...highChartsProps}
          />
        </div>
        <div style={{ minWidth: '50%' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={chart2Options}
            ref={chart2ComponentRef}
            {...highChartsProps}
          />
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const tableView = (limit?: number) => {
    limit = limit || trends?.length;

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              component="th"
              className={classes.tableItemCenter}
            ></TableCell>
            <TableCell
              component="th"
              style={{ width: '30%' }}
              className={classes.tableItemCenter}
            >
              {classificationType}
            </TableCell>
            <TableCell component="th" className={classes.tableItemCenter}>
              <div>{reportsPercent}</div>
              <div className={classes.periodInfo}>
                <div>{previousPeriod}</div>
                <div>
                  {formatDate(prevPeriodStart)} - {formatDate(prevPeriodEnd)}
                </div>
              </div>
            </TableCell>
            <TableCell component="th" className={classes.tableItemCenter}>
              <div>{reportsPercent}</div>
              <div className={classes.periodInfo}>
                <div>{currentPeriod}</div>
                <div>
                  {formatDate(currentPeriodStart)} -{' '}
                  {formatDate(currentPeriodEnd)}
                </div>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applyFilters(trends!)
            .slice(0, limit)
            .map((row) => {
              return (
                <TableRow key={row.label}>
                  <TableCell className={classes.tableItemCenter}>
                    <TrendIcon current={row.current} previous={row.prev} />
                  </TableCell>
                  <TableCell className={classes.tableItemCenter}>
                    {row.label}
                  </TableCell>
                  <TableCell className={classes.tableItemCenter}>
                    <Tooltip
                      title={`${formatPercentage3dp(row.prev)}`}
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <span>{formatPercentage1dp(row.prev)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.tableItemCenter}>
                    <Tooltip
                      title={`${formatPercentage3dp(row.current)}`}
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <span>{formatPercentage1dp(row.current)}</span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    );
  };

  const filterChips = (truncate?) => {
    const labels = truncate
      ? { up: 'Up', down: 'Down', flat: 'Flat' }
      : { up: 'Up Trend', down: 'Down Trend', flat: 'Flat Trend' };

    const chip = (className, filterName, label, current, prev) => {
      const appliedClasses = appliedFilterNames.includes(filterName)
        ? `${className} ${classes.activeFilter}`
        : className;
      return (
        <Grid item>
          <Chip
            className={classes.chip}
            classes={{ root: appliedClasses }}
            icon={<TrendIcon current={current} previous={prev} />}
            onClick={() => toggleFilterName(filterName)}
            label={label}
          />
        </Grid>
      );
    };

    return (
      <Grid spacing={1}>
        {chip(classes.up, filterNames.up, labels.up, 1, 0)}
        {chip(classes.down, filterNames.down, labels.down, 0, 1)}
        {chip(classes.flat, filterNames.flat, labels.flat, 1, 1)}
      </Grid>
    );
  };

  const headerComponent = (color = '#000000') => {
    return (
      <>
        <Typography variant={'h5'}>{classificationType}</Typography>
        {WidgetCode.topicTrends === widgetName ? (
          <Select
            labelId="filterTopicTrends"
            id="filterTopicTrends"
            defaultValue={0}
            value={selectedTopicOption}
            label="Model Filter"
            style={{ color: color, fontWeight: '500', width: '80%' }}
            classes={
              color !== '#000000'
                ? {
                    icon: _classes.iconWhite,
                  }
                : {
                    icon: _classes.iconBlack,
                  }
            }
            onChange={(evt) => handleTopicOptionChange(evt.target.value)}
          >
            {additionalTopicsOptions.length ? (
              additionalTopicsOptions.map((ct) => {
                return (
                  <MenuItem value={additionalTopicsOptions.findIndex((element) => element.key === ct.key)}>
                    <em>{ct.value ?? ct.name}</em>
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem value={0}>
                <em>Topics</em>
              </MenuItem>
            )}
          </Select>
        ) : null}
        <ToggleButtonGroup
          size="small"
          color="white"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="visual">Visual</ToggleButton>
          <ToggleButton value="table">Table</ToggleButton>
        </ToggleButtonGroup>
      </>
    );
  };

  return isLoading ? (
    <CircularProgressCentral />
  ) : (
    <Card variant="outlined" className={classes.root}>
      <CardHeader
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
            {headerComponent()}
          </div>
        }
        titleTypographyProps={{ variant: 'h6' }}
        action={filterChips(true)}
        className={classes.header}
      />
      <CardContent className={classes.cardContent}>
        {trends?.length > 0 ? (
          alignment === 'visual' ? (
            <VisualView />
          ) : (
            tableView()
          )
        ) : (
          // tableView(limit)
          <NoDataText label={noDataLabel} />
        )}
      </CardContent>
      <CardActions className={classes.cardActions}>
        {trends?.length > 0 && (
          <Button
            onClick={() => setShowModal(true)}
            className={classes.viewAllButton}
          >
            SEE ALL
          </Button>
        )}
      </CardActions>
      {showModal && (
        <DashboardModal
          title={<>{headerComponent('#FFFFFF')}</>}
          open={showModal}
          handleClose={() => setShowModal(false)}
        >
          {filterChips()}
          {alignment === 'visual' ? <VisualView /> : tableView()}
        </DashboardModal>
      )}
    </Card>
  );
};

export default withStyles(styles)(ClassificationTrendsBase);
